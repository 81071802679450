//sticky horizontal navbar
$(function() {
    var sticky_navigation_offset_top = $('nav').offset().top;
    var sticky_navigation = function(){
        var scroll_top = $(window).scrollTop();
        if (scroll_top > sticky_navigation_offset_top) {
            $('nav').addClass('sticky');
        } else {
            $('nav').removeClass('sticky');
        }
    };
    sticky_navigation();
    $(window).scroll(function() {
        sticky_navigation();
    });
    $('a[href="#"]').click(function(event){
        event.preventDefault();
    });
});